<template>
  <div style="margin:10px auto;display:block;">

    <motoBusqueda class="mb-5"></motoBusqueda>


      <v-layout row justify-center>
          <v-flex xs12 md9 class="my-5">
              <div style="width:90%;margin:0 auto;display:block;">
                  <v-card class="mb-3" flat style="background:white;border-radius:10px;
                  ">
                      <v-card-text>
                    <p>
                        <span style="font-size:30px;color:#00174A;line-height:30px;">
                          {{Producto.pr_nombre}} 
                      </span>
                    </p>
                    <p>
                          Ref: <span style="font-size:25px;color:red;font-weight:700">${{formatNumbe2r(Producto.pr_precio)}},00</span><br>
                      Bs.D: <span style="font-size:20px;color:red;font-weight:700">{{formatNumber(Producto.pr_precio * dolarDiario)}},00</span>
                      </p>
                      </v-card-text>
                  </v-card>
                  <v-carousel hide-delimiters height="600">

                  <v-carousel-item v-if="Producto.img1 != ''" :src="Producto.img1" @click="bigImg(Producto.img1)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img2 != ''" :src="Producto.img2" @click="bigImg(Producto.img2)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img3 != ''" :src="Producto.img3" @click="bigImg(Producto.img3)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img4 != ''" :src="Producto.img4" @click="bigImg(Producto.img4)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img5 != ''" :src="Producto.img5" @click="bigImg(Producto.img5)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img6 != ''" :src="Producto.img6" @click="bigImg(Producto.img6)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img7 != ''" :src="Producto.img7" @click="bigImg(Producto.img7)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img8 != ''" :src="Producto.img8" @click="bigImg(Producto.img8)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img9 != ''" :src="Producto.img9" @click="bigImg(Producto.img9)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="Producto.img10 != ''" :src="Producto.img10" @click="bigImg(Producto.img10)"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{Producto.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{Producto.pr_estado}}, {{Producto.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                </v-carousel>


                <div class="my-3">
                    <p class="title pa-5" style="color:#666">
                        {{Producto.pr_categoria}} EN {{Producto.pr_tipo}}
                    </p>
                    <p class="subtitle pa-5">
                        Ubicado en {{Producto.pr_sector}}, {{Producto.pr_municipio}}, {{Producto.pr_estado}}
                    </p>
                </div>
                <v-divider></v-divider>

                <div class="my-3">
                    <v-card flat color="white" elevation="6"
                    >
                        <v-card-text class="black--text" style="font-size:16px;font-weight:600">
                            Descripción
                        </v-card-text>
                        <v-card-text style="color:#5c727d;width:90%;margin:0 auto;display:block;">
                            <textarea v-model="Producto.pr_descripcion" disabled rows="15"
                            style="
                            height:auto;width:100%;
                            outlined:0;"
                            
                            >
                            </textarea>
                        </v-card-text>
                    </v-card>
                </div>

                <div style="margin-top:100px;">
                    <v-card flat color="white" elevation="6"
                    >
                        <v-card-text class="black--text" style="font-size:16px;font-weight:600">
                            Detalles del Inmueble
                        </v-card-text>
                        <v-card-text style="width:90%;margin:0 auto;display:block;">
                            <v-layout row justify-center>
                                <v-flex xs12 md4>
                                    <div style="width:95%;margin:0 auto;display;block;">
                                        <p style="color:black">
                                            <strong>Código:</strong> <span style="color:#5c727d">{{Producto.pr_codigo}}</span>
                                        </p>
                                        <p style="color:black">
                                            <strong>Área Terreno:</strong> <span style="color:#5c727d">{{Producto.pr_area_terreno}}</span>
                                        </p>
                                        <p style="color:black">
                                            <strong>Área de construcción:</strong> <span style="color:#5c727d">{{Producto.pr_area_bruta}}</span>
                                        </p>
                                        <p style="color:black">
                                            <strong>Plantas:</strong> <span style="color:#5c727d">{{Producto.pr_plantas}}</span>
                                        </p>
                                    </div>
                                </v-flex>

                                <v-flex xs12 md4>
                                    <div style="width:95%;margin:0 auto;display;block;">
                                        <p style="color:black">
                                            <strong>Ref:</strong> <span style="color:#5c727d">${{formatNumbe2r(Producto.pr_precio)}}</span>
                                        </p>
                                        <p style="color:black" v-if="Producto.pr_canon != ''">
                                            <strong>Precio Canon:</strong> <span style="color:#5c727d">${{formatNumbe2r(Producto.pr_canon)}}</span>
                                        </p>
                                        <p style="color:black" v-if="Producto.pr_canon != ''">
                                            <strong>Bs.D Canon:</strong> <span style="color:#5c727d">{{formatNumber(Producto.pr_canon * dolarDiario)}},00</span>
                                        </p>
                                        <p style="color:black">
                                            <strong>Bs.D:</strong> <span style="color:#5c727d">{{formatNumber(Producto.pr_precio * dolarDiario)}},00</span>
                                        </p>
                                    </div>                                    
                                </v-flex>

                                <v-flex xs12 md4>
                                    <div style="width:95%;margin:0 auto;display;block;">
                                        <p style="color:black">
                                            <strong>Habitaciones:</strong> <span style="color:#5c727d">{{Producto.pr_habitaciones}}</span>
                                        </p>
                                        <p style="color:black">
                                            <strong>Estacionamiento:</strong> <span style="color:#5c727d">{{Producto.pr_puestos}}</span>
                                        </p>
                                        <p style="color:black">
                                            <strong>Baños:</strong> <span style="color:#5c727d">{{Producto.pr_banos}}</span>
                                        </p>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </div>

                <div style="margin-top:50px;">
                    <v-card flat color="white" elevation="6"
                    >
                        <v-card-text class="black--text" style="font-size:16px;font-weight:600">
                            Comodidades
                        </v-card-text>
                        <v-card-text style="color:#5c727d;width:90%;margin:0 auto;display:block;">
                                <div style="width:90%;margin:0 auto;display:block;">
                                    <p>
                                      <i class="fas fa-check pr-1"></i> {{Producto.pr_comodidades}}
                                    </p>
                                </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text class="black--text" style="font-size:16px;font-weight:600">
                            Inmediaciones
                        </v-card-text>
                        <v-card-text style="color:#5c727d;width:90%;margin:0 auto;display:block;">
                                <div style="width:90%;margin:0 auto;display:block;">
                                    <p>
                                      <i class="fas fa-check pr-1"></i> {{Producto.pr_inmediaciones}}
                                    </p>
                                </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text class="black--text" style="font-size:16px;font-weight:600">
                            Servicios
                        </v-card-text>
                        <v-card-text style="color:#5c727d;width:90%;margin:0 auto;display:block;">
                                <div style="width:90%;margin:0 auto;display:block;">
                                    <p>
                                      <i class="fas fa-check pr-1"></i> {{Producto.pr_servicios}}
                                    </p>
                                </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text class="black--text" style="font-size:16px;font-weight:600">
                            Ambiente
                        </v-card-text>
                        <v-card-text style="color:#5c727d;width:90%;margin:0 auto;display:block;">
                                <div style="width:90%;margin:0 auto;display:block;">
                                    <p>
                                      <i class="fas fa-check pr-1"></i> {{Producto.pr_ambiente}}
                                    </p>
                                </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text class="black--text" style="font-size:16px;font-weight:600">
                            Ambiente Externo
                        </v-card-text>
                        <v-card-text style="color:#5c727d;width:90%;margin:0 auto;display:block;">
                                <div style="width:90%;margin:0 auto;display:block;">
                                    <p>
                                      <i class="fas fa-check pr-1"></i> {{Producto.pr_ambiente_ext}}
                                    </p>
                                </div>
                        </v-card-text>
                    </v-card>
                </div>


              </div>
          </v-flex>






          <v-flex xs12 md3 class="my-5">
              <div style="width:90%;margin:0 auto;display:block;">

          <v-card :elevation="6"
              flat style="border:solid 1px #ccc;border-radius:10px;" >
                  <v-img :src="DatosDelAsesor.img_profile" style="margin:0 auto;display:block;"></v-img>
                  <v-card-text>
                      <p style="font-size:30px;text-align:center;font-weight:700;line-height:30px;">
                          {{DatosDelAsesor.username}}
                      </p>
                      <div class="text-center pb-2" style="font-size:20px;font-weight:600;color:#D41700;font-style:italic;">
                          Asesor Inmobiliario
                      </div>
                      <p class="subtitle text-center"><strong>
                          <i class="fas fa-info-circle pl-2"></i> {{DatosDelAsesor.oficina}}
                          </strong></p>
                          <p>
                            <a :href="`mailto:${DatosDelAsesor.email}`" 
                            style="text-decoration:none;color:#333;font-size:10px;">
                                <i class="fas fa-mail-bulk"></i> {{DatosDelAsesor.email}}</a> <br><br>



                            <a :href="`https://api.whatsapp.com/send?phone=${DatosDelAsesor.tlf}&text=%20%20`" 
                            style="text-decoration:none;color:#333;font-size:15px;">
                                <i class="fab fa-whatsapp pt-2"></i> {{DatosDelAsesor.tlf}} <br><br></a>


                            <a target="_blank" :href="`https://tiktok.com/@${DatosDelAsesor.tiktok}`"
                             style="text-decoration:none;color:#333"
                            >
                                <v-img style="float:left" class="mt-1"
                                src="https://image.flaticon.com/icons/png/512/1946/1946552.png" 
                                height="12" width="12"></v-img> <span class="px-2">TikTok</span>
                            </a><br><br>

                            
                            <a target="_blank" :href="`https://instagram.com/${DatosDelAsesor.instagram}`"
                             style="text-decoration:none;color:#333"
                            >
                                <i class="fab fa-instagram pr-2"></i> Instagram
                            </a>
                          </p>
                          <div style="text-align:center">
                            <div id="qrcode" class="mt-5"></div>
                          </div>
                          <p class="pt-2 pb-2 text-center">
                              Ver todos los Inmuebles de
                              <strong>{{DatosDelAsesor.username}}</strong>
                          </p>
                  </v-card-text>
              </v-card>

              <p style="line-height:25px;font-size:15px;font-weight:700;padding:50px 0px 10px 15px;">
                  Ver más Inmuebles de {{DatosDelAsesor.username}}
                </p>

          <v-layout row justify-center>
            <v-flex xs12 style="margin:10px 0 25px 0;" v-for="(item, i) in MasInmuebles" :key="i">
              <div style="margin:0 auto;display:block;width:90%">
                    <v-carousel hide-delimiters height="300"
                    next-icon="fas fa-caret-right"
                    prev-icon="fas fa-caret-left"
                    >
                        <v-carousel-item v-if="item.img1 != ''" :src="item.img1">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img2 != ''" :src="item.img2">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img3 != ''" :src="item.img3">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img4 != ''" :src="item.img4">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img5 != ''" :src="item.img5">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img6 != ''" :src="item.img6">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img7 != ''" :src="item.img7">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img8 != ''" :src="item.img8">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img9 != ''" :src="item.img9">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img10 != ''" :src="item.img10">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                    </v-carousel>

                    <v-card style="background:white;border-radius:0;" flat>
                        <v-card-actions>
                            <v-btn text @click="irInmueble({asesor: item.pr_asesor_uid, id:item.pr_uid})"
                style="
                    border-radius:20px;color:grey;text-transform:none;
                    font-weight:700;text-decoration:none;font-size:14px;
                    ">Ver Detalles</v-btn>
                <v-spacer></v-spacer>
                <p class="pt-3" v-if="item.pr_precio != ''">
                    Ref: ${{formatNumber(item.pr_precio)}}
                </p>
                <p class="pt-3" v-if="item.pr_canon != ''">
                    Ref: ${{formatNumber(item.pr_canon)}}
                </p>
                        </v-card-actions>
                    </v-card>
                
                </div>
            </v-flex>
          </v-layout>

        </div>

          </v-flex>
      </v-layout>


      <v-dialog width="600" v-model="dialogImg" persistent>
          <v-card flat width="600">
              <v-img :src="bigImagen">
                  <v-btn icon @click="bigImagen = '', dialogImg = !dialogImg"
                  style="background:red;color:white;position:absolute;right:10px;top:10px"
                  >X</v-btn>
              </v-img>
          </v-card>
      </v-dialog>


  </div>
</template>

<style>
.dfdfg{
    width:100%;
background: rgba(0,88,176,1);
background: -moz-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,88,176,1)), color-stop(100%, rgba(1,43,82,1)));
background: -webkit-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -o-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -ms-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: linear-gradient(to bottom, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0058b0', endColorstr='#012b52', GradientType=0 );
}
</style>


<script>
import QRCode from 'qrcodejs2'
import motoBusqueda from '../components/motorBusqueda.vue'
import axios from 'axios'
import {mapState} from 'vuex'
export default {
    data(){
        return {
            externalScript:null,
            asesor:'',
            id:'',Producto:[],DatosDelAsesor:[],MasInmuebles:[],dialogImg:false,bigImagen:''
        }
    },
    components:{
      motoBusqueda//,VueQr
    },
    computed:{
        ...mapState(['dolarDiario'])
    },
    methods:{
        irInmueble(e){
            const _ID = e.id;
            const _ASESOR = e.asesor;
            const _URL = "/Inmueble?inmu="+_ID+"&asesor="+_ASESOR;
            window.open(_URL, "_self")
        },
        bindQRCode(){
            //new QRCode(document.getElementById("qrcode"), `https://www.inmoglobalve.com.ve/Inmueble/${this.id}/`+this.asesor);
            var qrcode = new QRCode(document.getElementById("qrcode"), {
            text: "Ver Inmuebles del Asesor "+this.DatosDelAsesor.username,
            colorDark : "#000000",
            colorLight : "#ffffff",
            correctLevel : QRCode.CorrectLevel.H
        });
        qrcode.clear(); // clear the code.
        qrcode.makeCode(`https://www.inmoglobalve.com.ve/Asesor?uid=${this.asesor}`); // make another code.
        },
        formatNumber(n) {
            n = String(n).replace(/\D/g, "");
        return n === '' ? n : Number(n).toLocaleString('es');
        },
        formatNumbe2r(n) {
            n = String(n).replace(/\D/g, "");
        return n === '' ? n : Number(n).toLocaleString('es');
        },
        bigImg(e){
          this.bigImagen = e
          this.dialogImg = true
        },
        async loadProductoSingle(e){
            try {
                await axios.post('https://inmoglobalve.com.ve/php/Productos/getSingleProducto.php', {
                    pr_uid:e
                }).then(res=>{
                    this.Producto = res.data[0]
                })
            } catch (error) {
                console.log(error)
            }
        },
        loadAsesorResponsable(e){
            const URL_INMOGLOBAL_VE = 'https://inmoglobalve.com.ve/php/LoadAsesoresSingle.php';
            axios.post(URL_INMOGLOBAL_VE,{uid:e}).then(res=>{
                this.DatosDelAsesor = res.data[0]
            })
        },
        loadMasInmuebles(e){
            const URL_INMOGLOBAL_VE = 'https://inmoglobalve.com.ve/php/Productos/MasInmuebles.php';
            axios.post(URL_INMOGLOBAL_VE,{uid:e}).then(res=>{
                this.MasInmuebles = res.data
            })
        },
    },
    mounted(){
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        this.id = urlParams.get('inmu')
        this.asesor = urlParams.get('asesor')
        this.loadProductoSingle(this.id),this.loadAsesorResponsable(this.asesor),this.loadMasInmuebles(this.asesor),
        this.bindQRCode()
    }
}
</script>
